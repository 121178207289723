.auth-nav {
  width: 410px;
  display: flex;
  justify-content: space-between;
}
.logout-btn {
  margin-left: 25px;
}
.header-button {
  background: rgb(233, 172, 172);
  border: 1px solid rgb(182, 128, 128);
  border-radius: 6px;
  height: 35px;
  width: 98px;
  padding: 0;
  font-weight: 600;
  color: rgb(62, 59, 59);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.header-button:hover,
.header-button:focus {
  box-shadow: 3px 2px 2px rgb(83, 82, 80);
}
.active {
  background: rgb(176, 99, 99);
}
